<template>
  <div class="">
    <el-row>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="课程标题">
          <el-input v-model="title"></el-input>
        </el-form-item>
        <el-form-item label="所属机构">
          <el-select
            filterable
            v-model="mechanism_id"
            clearable
            remote
            :remote-method="searchOrgName"
            @focus="searchOrgName"
          >
            <el-option
              v-for="(option, index) in mechanism_name_list"
              :key="index"
              :value="option.id"
              :label="option.mechanism_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="status" clearable style="width: 120px">
            <el-option label="已通过" :value="2"></el-option>
            <el-option label="已拒绝" :value="1"></el-option>
            <el-option label="待审核" :value="4"></el-option>
            <el-option label="已下架" :value="5"></el-option>
            <el-option label="已冻结" :value="6"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="study_type" style="width: 120px" clearable>
            <el-option label="标准课" value="mechanism_offline"></el-option>
            <el-option label="天赋课" value="talent"></el-option>
            <el-option label="定制课" value="custom_offline"></el-option>
            <el-option label="休闲生活" value="休闲生活"></el-option>
             <el-option label="团课" value="group"></el-option>
            <el-option label="社区课" value="community"></el-option>
            <el-option label="夜校" value="夜校"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search('course')">查询</el-button>
          <el-button
            @click="openAddCourse"
            type="primary"
            style="margin-left: 20px"
            >新增课包</el-button
          >
          <el-button
            @click="openCommunityAdd"
            type="primary"
            style="margin-left: 20px"
            >新增社区课</el-button
          >
        </el-form-item>
        <el-form-item>
          <excelExport
            ref="export"
            :tableName="tableName"
            :values="values"
            :titles="titles"
            @startExport="startExport"
          >
          </excelExport>
        </el-form-item>
      </el-form>

      <el-table :data="courseList" v-loading="isLoading">
        <el-table-column type="index"> </el-table-column>
        <el-table-column label="课程标题" prop="title" width="150">
          <template slot-scope="{ row }">
            <div class="overhide">
              <span v-if="row.type == 'promote_course'" class="orange"
                >推广展示课</span
              >
              {{ row.title }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="课程类目" prop="categories_child">
        </el-table-column>
        <el-table-column label="总课节数" prop="course_num">
          <template slot-scope="{ row }">
            <div class="">
              {{ row.course_num }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="课程购买数" prop="pay_num"></el-table-column>
        <el-table-column
          label="课程价格"
          prop="discount_amout"
        ></el-table-column>
        <el-table-column
          label="所属机构"
          width="150"
          prop="map.masterMechanismEntity.mechanism_name"
        >
          <template slot-scope="{ row }">
            <el-popover
              trigger="hover"
              placement="top"
              v-if="row.map.masterMechanismEntity"
            >
              <p>{{ row.map.masterMechanismEntity.mechanism_name }}</p>
              <div class="overhide" slot="reference">
                {{ row.map.masterMechanismEntity.mechanism_name }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <!-- <el-table-column label="权益点数" width="100px" align="center">
          <template slot-scope="{ row }">
            <div class="flex jc-a al-c">
              <div>
                <span>{{ row.pay_point }}</span>
              </div>
              <el-button type="text" @click="choosePoint(row)">调整</el-button>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column label="是否推荐" prop="is_recommend">
          <template slot-scope="scope">
            {{ scope.row.is_recommend ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="{ row }">
            <div v-if="row.is_conceal === 0">
              <el-tag v-if="row.status == 4">待审核</el-tag>
              <el-tag type="success" v-if="row.status == 2">已通过</el-tag>
              <el-tag type="info" v-if="row.status == 1">审核拒绝</el-tag>
              <el-tag type="warning" v-if="row.status == 5">下架</el-tag>
              <el-tag type="danger" v-if="row.status == 6">冻结</el-tag>
            </div>
            <div v-else>
              <el-tag type="info">已隐藏</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="" width="250">
          <template slot-scope="scope">
            <div class="flex flex-wrap">
              <!-- <el-button size="small">下架</el-button> -->
              <el-button
                size="small"
                type="warning"
                v-if="$route.query.type == 'buy'"
                @click="exchangeCourse(scope.row)"
                >兑换
              </el-button>
              <el-button
                size="small"
                type="warning"
                v-else-if="$route.query.type == 'change'"
                @click="changeCourse(scope.row)"
                >换课
              </el-button>
              <div v-else>
                <el-button
                  size="small"
                  type="primary"
                  v-if="scope.row.status == 4"
                  @click="openCheck(scope.row)"
                  >审核</el-button
                >
                <el-button
                  size="small"
                  type="warning"
                  @click="openEditCourse(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="small"
                  type="danger"
                  @click="addcommit(scope.row)"
                  >添加评论
                </el-button>
                <!-- <el-button size="small" type="success" @click="buycount(scope.row)">购买数据
                </el-button>
                <el-button size="small" type="warning" @click="shareLink(scope.row)">分享</el-button> -->
                <el-dropdown
                  size="small"
                  @command="command"
                  @visible-change="changedrop(scope.row)"
                  trigger="click"
                >
                  <el-button type="primary" size="small">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="share">分享</el-dropdown-item>
                    <el-dropdown-item command="pay_num"
                      >购买数据</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="scope.row.status == 2"
                      command="frozen"
                      >冻结</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="scope.row.status == 6"
                      command="unfrozen"
                      >解冻</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="scope.row.is_conceal == 0"
                      command="hide"
                      >隐藏</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="scope.row.is_conceal == 1"
                      command="unhide"
                      @click.native="hideCourse(scope.row, 1)"
                      >取消隐藏</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <paging-fy
        @currentPageChange="currentPageChange"
        :currentPage="currentPage"
        :total="courseTotal"
      ></paging-fy>
    </el-row>
    <!-- 调整权益点 -->
    <el-dialog
      title="调整权益点"
      :visible.sync="exdialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div>
        <el-form
          class="demo-form-inline"
          label-width="120px"
          label-position="left"
        >
          <el-form-item label="快捷填写">
            <el-input
              v-model="all_point"
              placeholder="填写后所有点数都一样"
              @input="setAllPoint"
            ></el-input>
          </el-form-item>
          <el-form-item label="尊享卡点数">
            <el-input
              v-model="premium_point"
              placeholder="尊享卡点数"
            ></el-input>
          </el-form-item>
          <el-form-item label="尊享卡高峰点数">
            <el-input
              v-model="premium_point_big"
              placeholder="尊享卡高峰点数"
            ></el-input>
          </el-form-item>
          <el-form-item label="普通点数">
            <el-input v-model="pay_point" placeholder="普通点数"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitChange">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改购买数 -->
    <el-dialog
      title="修改购买数"
      :visible.sync="buydialogVisible"
      :width="ks ? '90%' : '30%'"
      :before-close="handleClose"
    >
      <div>
        <el-form
          class="demo-form-inline"
          label-width="120px"
          label-position="left"
        >
          <el-form-item label="修改数据">
            <el-radio-group v-model="edit_info_num">
              <el-radio label="exchange">课程可兑换数量</el-radio>
              <el-radio label="buy">课程已购买数量</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="可兑换课程数" v-if="edit_info_num == 'exchange'">
            <el-input
              v-model="restricted_exchange_count"
              key="ex_input"
            ></el-input>
          </el-form-item>

          <el-form-item label="购买课程数" v-if="edit_info_num == 'buy'">
            <el-input
              v-model="buy_num"
              placeholder="购买课程数"
              key="buy_input"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="buydialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="updateExchange"
          v-if="edit_info_num == 'exchange'"
          >修改兑换数量</el-button
        >
        <el-button
          type="primary"
          @click="submitbuycount"
          v-if="edit_info_num == 'buy'"
          >修改购买数量</el-button
        >
      </span>
    </el-dialog>

    <!-- 课程二维码 -->
    <el-dialog
      title="课程二维码"
      :visible.sync="qrdialogVisible"
      :width="ks ? '90%' : '40%'"
      :before-close="handleClose"
    >
      <div class="flex jc-c">
        <img :src="codeimg" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qrdialogVisible = false">确定</el-button>
      </span>
    </el-dialog>

    <!-- 添加评论 -->
    <el-dialog
      title="添加评论"
      :visible.sync="comdialogVisible"
      :destroy-on-close="true"
    >
      <div>
        <el-form
          class="demo-form-inline"
          label-width="120px"
          label-position="left"
        >
          <el-form-item label="课程名称">
            {{ curCourse.title }}
          </el-form-item>

          <el-form-item label="添加类型" required>
            <el-checkbox-group v-model="edit_commit_type">
              <el-checkbox label="course">课程评论</el-checkbox>
              <el-checkbox label="circle">圈子</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="内容" required>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="commit_content"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="图片">
            <images-uploader
              :clear="true"
              @getImgUrl="(v) => (commit_pic = v)"
              :urls="commit_pic"
            ></images-uploader>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="comdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCommit">确 定</el-button>
      </span>
    </el-dialog>

    <addCourse
      :mode="mode"
      :courseData="courseData"
      ref="addCou"
      @success="getCourseList"
    ></addCourse>
    <communityCourseEdit
      ref="communityCourse"
      @success="communitySuccess"
    ></communityCourseEdit>
  </div>
</template>
  
<script>
import addCourse from "./addCourse.vue";
import excelExport from "@/components/excel/excelExport.vue";
import communityCourseEdit from "@/components/course/communityCourseEdit.vue";
export default {
  components: {
    addCourse,
    excelExport,
    communityCourseEdit,
  },
  data() {
    return {
      ks: "",
      selected_user: "",
      userclass: false,
      nav: {
        firstNav: "课程管理中心",
        secondNav: "课程/场馆服务列表",
      },
      courseData: {},
      mode: "add",
      addShow: false,
      updateServiceDialog: false,
      qrdialogVisible: false,
      tea_slecet_list: [],
      type: "mechanism_offline",
      isLoading: true,
      if_clear: false,
      if_new: false,
      activeName: "first",
      // 课程列表
      currentPage: 1,
      courseTotal: null,
      courseList: [],
      count: 1,
      userList: [],
      // 搜索
      mechanism_id: null,
      experience_course: [],
      status: 2,
      mechanism_name_list: [],
      cancelToken: null,
      updateCourseDialog: false,
      mechanism_type: "",
      categories_child_list: [],
      haveTelente: true,
      exchangeShow: false,

      title: null,
      addServiceDialog: false,
      master_name: "",
      course_type: "haimacourse",
      cardLevel: [],
      allCardLevel: [],
      // 课程类型图表
      // courseType: [],
      study_type: "mechanism_offline",
      timeoutID: null,
      temp: {},
      restricted_exchange_count: null,
      form: {},
      form1: {},
      chooseId: "",
      premium_point: 0,
      premium_point_big: 0,
      pay_point: 0,
      venues_list: [],
      addDialog: false,
      card_type: [],
      updateDialog: false,
      age_type_list: [],
      school_age_list: [],
      course_num: null,
      cancel: null,
      tea_list: [],
      exdialogVisible: false,
      oldPoint: {},
      curCourse: {},
      studycard_id: "",
      buy_num: 0,
      comdialogVisible: false,
      buydialogVisible: false,
      commit_content: "",
      commit_pic: "",
      MecCourseList: {},
      is_recommend: false,
      all_point: "",
      edit_info_num: "buy",
      edit_commit_type: ["course"],
      can_consume: 0,
      small_price: 0,
      access: "",
      codeimg: "",
      reindex: 3,
      cityoptions: [],
      cityProps: {
        value: "district",
        label: "district",
        children: "childrens",
      },
      cityList: [],
      select_mechanism: [],
      checked: false,
      tableName: "海马运动课程",
      values: [
        "title",
        "categories_child",
        "course_num",
        "discount_amout",
        "pay_num",
        "consume",
        "settlement_cash",
        "mechanism_name",
        "city",
        "venues_addr",
        "settlement_type",
      ],
      titles: [
        "课程名",
        "类目",
        "课节数",
        "价格",
        "销量",
        "可用消费金",
        "结算金额",
        "机构名称",
        "城市",
        "地址",
        "结算方式",
      ],
    };
  },

  methods: {
    communitySuccess() {
      this.getCourseList();
    },
    openCommunityAdd() {
      this.$refs.communityCourse.open();
    },
    // 导出表格
    startExport() {
      this.exportInfo();
    },
    exportInfo() {
      let DataList = [];
      this.$axios({
        url: "/user/masterSetPrice/queryExportList",
        params: {
          status: 2,
          city: "杭州市",
        },
      }).then((v) => {
        const loading = this.$loading({
          lock: true,
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        v.data.data.forEach((item) => {
          item.settlement_type =
            item.settlement_type === 1 ? "单节结算" : "一次性结算";
        });
        DataList = v.data.data;
        loading.close();
        this.$refs.export.importExcel(DataList);
      });
    },
    openCheck(row) {
      this.courseData = row;
      this.mode = "check";
      this.$refs.addCou.open();
    },
    openAddCourse() {
      this.courseData = {};
      this.mode = "add";
      this.$refs.addCou.open();
    },
    openEditCourse(row) {
      if (row.is_community) {
        this.$refs.communityCourse.open(row);
      } else {
        this.courseData = row;
        this.mode = "edit";
        this.$refs.addCou.open();
      }
    },
    // 机构全选
    selectAll(v) {
      if (v) {
        this.select_mechanism = this.mechanism_name_list.map((item) => {
          return item.id;
        });
        this.getAddress(this.mechanism_name_list[0]);
      } else {
        this.select_mechanism = [];
      }
    },

    //换课
    changeCourse(row) {
      this.$router.push({
        name: "refundDetails",
        query: {
          type: "change",
          id: this.$route.query.id,
          oid: this.$route.query.oid,
          cid: row.id,
          title: row.title,
          course_num: row.course_num,
          amount: row.discount_amout,
        },
      });
    },
    command(v) {
      switch (v) {
        case "share":
          this.shareLink();
          break;
        case "pay_num":
          this.buycount();
          break;
        case "frozen":
          this.frozen();
          break;
        case "unfrozen":
          this.unfrozen();
          break;
        case "hide":
          this.hideCourse(1);
          break;
        case "unhide":
          this.hideCourse(0);
          break;
        default:
          break;
      }
    },
    changedrop(row) {
      this.curCourse = row;
    },
    hideCourse(status) {
      let url = "user/masterSetPrice/update";
      let data = {
        id: this.curCourse.id,
        is_conceal: status,
      };
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "修改成功!" });
          this.getCourseList();
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    shareLink() {
      this.qrdialogVisible = true;
      this.getQrCode();
    },
    getQrCode() {
      let page = "pages/work/classChannel/classChannel";
      if (this.curCourse.type == "custom_offline") {
        page = "pages/work/classChannel/customClass";
      }
      let data = {
        scene: "curriculumId=" + this.curCourse.id,
        page: page,
      };
      this.$axios({
        url: "/user/user/generateQRCodeSportPic",
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.codeimg = res.data.data.img;
        } else {
          this.reindex++;
          if (this.reindex > 3) {
            return;
          } else {
            let id = setTimeout(() => {
              this.getQrCode();
              clearTimeout(id);
            }, 1000);
          }
        }
      });
    },

    getUser(query) {
      this.user_list = [];
      if (query && typeof query == "string") {
        let url = "/user/user/queryByMessage";
        this.$axios
          .get(url, {
            params: {
              login_name: query,
            },
          })
          .then((res) => {
            this.userList = res.data.data.rows;
          });
      }
    },
    changeMec(e) {
      if (this.userclass || this.temp.type == "custom_offline") {
        this.getMecCourse();
      }
    },

    upload_imgs(val) {
      this.commit_pic = val;
    },
    addcommit(row) {
      this.curCourse = row;
      this.comdialogVisible = true;
    },
    buycount() {
      this.buydialogVisible = true;
      this.buy_num = this.curCourse.pay_num;
    },
    //添加评论、圈子
    submitCommit() {
      if (this.edit_commit_type.includes("course")) {
        let data = { content: this.commit_content };
        let url = "user/masterComment/insertRobot";
        data.photo_url = this.commit_pic;
        data.mechanism_id = this.curCourse.mechanism_id;
        data.mastersetprice_id = this.curCourse.id;
        this.$axios({
          url,
          method: "post",
          data,
        }).then((res) => {
          this.$message({ type: "warning", message: "添加成功!" });
          this.comdialogVisible = false;
        });
      }
      if (this.edit_commit_type.includes("circle")) {
        let data = { content: this.commit_content };
        let url = "message/noteUser/insertRobot";
        data.master_set_price_id = this.curCourse.id;
        data.picts = this.commit_pic;
        data.mechanism_id = this.curCourse.mechanism_id;
        this.$axios({
          url,
          method: "post",
          data,
        }).then((res) => {
          this.$message({ type: "warning", message: "添加成功!" });
          this.comdialogVisible = false;
        });
      }
      this.edit_commit_type = [];
    },
    //修改购买数量
    submitbuycount() {
      let num = this.buy_num;
      let data = {
        id: this.curCourse.id,
        pay_num: num,
      };
      this.$axios({
        url: "user/masterSetPrice/update",
        method: "post",
        data,
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "修改成功!" });
          this.getCourseList();
          this.buydialogVisible = false;
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    //兑换课程
    exchangeCourse(row) {
      this.curCourse = row;
      this.exchangePoint2Course();
    },

    exchangePoint2Course() {
      let data = {};
      let url = "";
      url = "/user/pay/creation";
      data = {
        user_id: this.$route.query.id,
        rcharge_type: "study_card",
        course_num: this.curCourse.course_num,
        member_duration: 12,
        rcharge_abstract: "全额购",
        studycard_id: this.curCourse.id,
        study_type: "mechanism_offline",
        is_one_time_payment: false,
        pay_type: "point",
        source: "android",
        is_member: false,
        consume: 0,
        mechanism_id: this.curCourse.mechanism_id,
      };
      if (this.$route.query.coup_id) {
        data.coupon_ids = this.$route.query.coup_id;
        data.pay_type = "wx";
      }
      this.$axios({
        url: url,
        method: "post",
        data,
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "兑换成功!" });
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    setAllPoint() {
      this.premium_point = this.all_point;
      this.premium_point_big = this.all_point;
      this.pay_point = this.all_point;
    },
    //选择调整权益点课程
    choosePoint(row) {
      this.chooseId = row.id;
      this.exdialogVisible = true;
      this.premium_point = row.premium_point;
      this.premium_point_big = row.premium_point_big;
      this.pay_point = row.pay_point;
      this.oldPoint.premium_point = row.premium_point;
      this.oldPoint.premium_point_big = row.premium_point_big;
      this.oldPoint.pay_point = row.pay_point;
    },
    //修改权益点数
    submitChange() {
      let url = "user/masterSetPrice/update";
      let data = {};
      data.id = this.chooseId;
      data.premium_point = this.premium_point;
      data.premium_point_big = this.premium_point_big;
      data.pay_point = this.pay_point;

      this.$axios.post(url, data).then((res) => {
        this.all_point = "";
        // this.premium_point = 0;
        // this.premium_point_big = 0;
        // this.pay_point = 0;
        this.exdialogVisible = false;
        this.getCourseList();
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "修改成功!" });
          this.getCourseList();
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    handleClick(tab, event) {
      if (tab.index == 0) {
        this.type = "mechanism_offline";
        this.study_type = "mechanism_offline";
        this.getCourseList();
      } else if (tab.index == 1) {
        this.getServiceList();
      }
    },

    load() {
      this.count++;
      this.getTeaList(this.count);
    },
    getBindTea(v) {
      this.form.bind_master_ids = v.join();
    },
    getTeaList(currentPage) {
      let url = "/user/masterInfo/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            mechanism_id: this.form.mechanism_id,
            type: "教练",
            currentPage: currentPage || 1,
            pageSize: 10,
          },
        })
        .then((res) => {
          this.tea_list = [...this.tea_list, ...res.data.data.rows];
        });
    },
    updateExchange() {
      let url = "/user/masterSetPrice/updateExchangeCount";
      this.$axios
        .post(url, {
          id: this.curCourse.id,
          restricted_exchange_count: this.restricted_exchange_count,
          mechanism_id: this.curCourse.mechanism_id,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "更新成功!",
              type: "success",
            });
            this.buydialogVisible = false;
            this.getCourseList();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
    },

    search(type) {
      this.currentPage = 1;
      switch (type) {
        case "service":
          this.getServiceList();
          break;
        case "course":
          this.getCourseList();
          break;
      }
    },
    getTelentFace(v) {
      this.form1.face_url1 = v;
    },
    getTelentIntroduce(v) {
      this.form1.introduction_url1 = v;
    },
    getCover(v) {
      this.form.face_url = v;
    },
    mechanism_name(id) {
      let a = this.mechanism_name_list.find((item, index, arr) => {
        return item.id == id;
      });
      if (a) {
        return a.mechanism_name;
      } else return {};
    },

    getProductImg(v) {
      // console.log(v);
      this.$set(this.form, "introduction_url", v);
    },
    caculate() {
      this.getServiceLowPoint();
      this.getServiceHeightPoint();
    },

    getCateList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      let data = {
        status: 2,
        type: 2,
        source: "课程",
      };
      if (this.course_type == "art" || this.study_type == "art_offline") {
        data.source = "休闲生活";
      }
      this.$axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          this.categories_child_list = res.data.data;

          // this.typeTotal = res.data.data.total;
        });
    },
    changeGoodType(v) {
      this.mechanism_id = null;
      this.mechanism_name_list = [];
      this.searchOrgName();
    },

    getServiceList() {
      let url = "/user/masterSetPrice/querySimpleListByIdAll";
      this.$axios
        .get(url, {
          params: {
            type: "场馆",
            mechanism_id: this.mechanism_id ? this.mechanism_id : null,
            pageSize: 10,
            // is_school: false,
            currentPage: this.currentPage,
            title: this.title ? this.title : null,
            binding_venues_id: this.binding_venues_id
              ? this.binding_venues_id
              : null,
          },
        })
        .then((res) => {
          this.courseList = res.data.data.rows;
          this.courseTotal = res.data.data.total;
          this.isLoading = false;
        });
    },
    // 课程列表分页获取
    getCourseList() {
      let data = {
        status: this.status ? this.status : null,
        categories: "体育运动",
        // source: "体育宝",
        pageSize: 10,
        currentPage: this.currentPage,
        title: this.title ? this.title : null,
        mechanism_id: this.mechanism_id ? this.mechanism_id : null,
      };
      if (this.$route.query.coup_id) {
        data.card_type = "优学卡";
      }
      if (this.study_type == "community") {
        data.is_community = true;
      }if (this.study_type == "group") {
        data.is_group = true;
      } else if (this.study_type == "talent") {
        data.card_type = "优学卡";
      } else if (["休闲生活", "夜校"].includes(this.study_type)) {
        data.categories = this.study_type;
      } else {
        data.type = this.study_type || null;
      }
      this.isLoading = true;
      let url = "/user/masterSetPrice/queryByMessage";
      this.$axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          this.courseList = res.data.data.rows;
          this.courseTotal = res.data.data.total;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    currentPageChange(v) {
      this.currentPage = v;
      this.getCourseList();
    },
    pass(row) {
      let url = "/user/masterSetPrice/update";
      this.$axios
        .post(url, {
          id: row.id,
          status: 2,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "修改成功!" });
            this.getCourseList();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    refuse(row) {
      let url = "/user/masterSetPrice/update";
      this.$axios
        .post(url, {
          id: row.id,
          status: 1,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "修改成功!" });
            this.getCourseList();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    changeCardType(v) {
      let list = [];
      v.forEach((item) => {
        list.push(item[0]);
      });
      this.$set(this.form, "card_type", list.join(","));
    },
    frozen(row) {
      let url = "/user/masterSetPrice/update";
      this.if_clear = false;
      this.$axios
        .post(url, {
          id: this.curCourse.id,
          status: 6,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
            this.updateDialog = false;
            this.getCourseList();
            this.if_clear = true;
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    unfrozen(row) {
      let url = "/user/masterSetPrice/update";
      this.if_clear = false;
      this.$axios
        .post(url, {
          id: this.curCourse.id,
          status: 2,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
            this.updateDialog = false;
            this.getCourseList();
            this.if_clear = true;
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },

    submitCourseForm() {
      this.$refs.courseForm.validate((valid) => {
        if (valid) {
          this.addNewService();
        }
      });
    },
    getCardLevel() {
      let url = "/user/userMemberLevel/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            // platform: "体育宝",
            status: 1,
            type: 2,
          },
        })
        .then((res) => {
          this.cardLevel = res.data.data.rows.filter((item) => {
            return item.parent_id != 0;
          });
        });
    },

    getAgeType() {
      let url = "/user/userServiceAge/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            pageSize: 10,
            currentPage: 1,
            platform: "年级",
          },
        })
        .then((res) => {
          this.age_type_list = res.data.data.rows;
        });
    },
    getSchoolAgeType() {
      let url = "/user/userServiceAge/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            pageSize: 10,
            currentPage: 1,
            platform: "学龄",
          },
        })
        .then((res) => {
          this.school_age_list = res.data.data.rows;
        });
    },

    // 机构查询远程查询
    searchOrgName(query) {
      if (query && typeof query == "string") {
        let url = "/user/mastermechanism/queryByMessage";
        let data = {
          type: "teach_paypal",
          // categories: "体育运动",
          // source: "体育宝",
          pageSize: 100,
          currentPage: 1,
          status: 2,
        };
        const numberReg = /^\d/;
        numberReg.test(query)
          ? (data.mechanism_telephone = query)
          : (data.mechanism_name = query);
        if (this.course_type == "art") {
          data.type = "机构商户";
        }
        this.$axios
          .get(url, {
            params: data,
          })
          .then((res) => {
            // console.log(res);
            this.mechanism_name_list = res.data.data.rows;
          })
          .catch((err) => {});
      }
    },
    changeMechanismType(v) {
      this.mechanism_name_list = [];
    },

    // 课程详情页
    toCouDetails(row) {
      let couMec = "";
      if (this.activeName == "second") {
        couMec = row.map.mechanismInfo.mechanism_name;
      } else {
        couMec = row.map.masterMechanismEntity.mechanism_name;
      }
      this.$router.push({
        name: "couDetails",
        query: {
          type: "mec",
          couID: row.id,
          couMec,
        },
      });
    },
    levelName(id) {
      let a = this.allCardLevel.find((item, index, arr) => {
        return item.id == id;
      });
      if (a) {
        return a;
      } else return {};
    },
    getAllCardLevel() {
      let url = "/user/userMemberLevel/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            // platform: "体育宝",
            status: 1,
          },
        })
        .then((res) => {
          this.allCardLevel = res.data.data.rows;
          this.allCardLevel.findIndex;
        });
    },
    immclose() {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.addServiceDialog = false;
          done();
        })
        .catch((_) => {});
    },
    handleClose(done) {
      this.if_clear = false;
      this.$confirm("确认关闭？")
        .then((_) => {
          this.addDialog = false;
          this.updateDialog = false;
          this.updateServiceDialog = false;
          this.exchangeShow = false;
          this.if_clear = true;
          this.course_num = null;
          this.temp = {};
          this.card_type = [];
          this.form = {};
          done();
        })
        .catch((_) => {});
    },
  },
  mounted() {
    this.cityoptions = JSON.parse(localStorage.getItem("addrList"));
    this.ks = window.screen.width < 768;
    this.getCourseList();
    this.getCateList();
    this.getAgeType();
    this.getSchoolAgeType();
    // this.getAllCardLevel()
  },
};
</script>
  
<style>
.el-table thead tr th {
  background-color: #eaeaea !important;
}

.overhide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-button {
  margin-bottom: 5px;
}

.el-button + .el-button {
  margin-left: 0;
}
</style>
  